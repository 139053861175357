import React, { useState, useEffect } from 'react';
import { wmsCapabilities, getWFSURL, wfsGetFields, wfsGetDownloadURLs } from '../OGCUtils';
import { IcoRefreshSmall, IcoTrashOrange } from '../../img/ico/icoBtn';
import './formtab.css';

const splitSubgroups = (subgrp) => { 
    return subgrp.split(/(?<!\([^)]*)\/(?![^()]*\))/g);
};

const AutocompleteInput = ({ value, onChange, options, sort = true }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [showOptions, setShowOptions] = useState(false);

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue) {
            const filtered = options.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
        setShowOptions(true);
        onChange(inputValue);
    };

    const handleSelect = (option) => {
        onChange(option);
        setShowOptions(false);
    };

    let items = filteredOptions;
    if (sort)
        items = items.sort(new Intl.Collator('pt-BR',{caseFirst: 'false'}).compare)

    return (
        <div className="autocomplete">
            <input
                type="text"
                value={value}
                onChange={handleChange}
                onBlur={() => setTimeout(() => setShowOptions(false), 100)}
                onFocus={() => setShowOptions(true)}
                placeholder="Digite ou selecione uma opção"
            />
            {showOptions && items.length > 0 && (
                <ul className="autocomplete-options">
                    {items.map((option, index) => (
                        <li key={index} onClick={() => handleSelect(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const FormTabBaseMaps = ({ formData, initialID, existingTypes, onChange }) => {
    const [formState, setFormState] = useState({
        ID: initialID || formData?.ID || '',
        Nome: formData?.Nome || '',
        URL: formData?.URL || '',
        Atribuições: formData?.Atribuições || '',
        Padrão: formData?.Padrão,
        Tipo: formData?.Tipo || '',
        Prioridade: formData?.Prioridade || '',
        ZoomMin: formData?.ZoomMin || '',
        ZoomMax: formData?.ZoomMax || ''
    });
    const handleInputChange = (field, value) => {
        const updatedFormState = { ...formState, [field]: value };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    console.log(formState.Padrão)

    return (
        <form className="edit-form">
            <label>ID</label>
            <input
                type="text"
                value={formState.ID}
                readOnly
                className="inactive-input"
            />

            <label>Nome</label>
            <input
                type="text"
                value={formState.Nome}
                onChange={(e) => handleInputChange('Nome', e.target.value)}
            />

            <label>URL</label>
            <input
                type="text"
                value={formState.URL}
                onChange={(e) => handleInputChange('URL', e.target.value)}
            />

            <label>Atribuições</label>
            <input
                type="text"
                value={formState.Atribuições}
                onChange={(e) => handleInputChange('Atribuições', e.target.value)}
            />

            <label>Padrão?</label>
            <input
                type="checkbox"
                checked={formState.Padrão}
                onChange={(e) => handleInputChange('Padrão', e.target.checked)}
            />

            <label>Tipo</label>
            <AutocompleteInput
                value={formState.Tipo}
                onChange={(value) => handleInputChange('Tipo', value)}
                options={existingTypes}
            />

            <label>Prioridade</label>
            <input
                type="text"
                value={formState.Prioridade}
                onChange={(e) => handleInputChange('Prioridade', e.target.value)}
            />

            <label>Zoom Min.</label>
            <input
                type="text"
                value={formState.ZoomMin}
                onChange={(e) => handleInputChange('ZoomMin', e.target.value)}
            />

            <label>Zoom Máx.</label>
            <input
                type="text"
                value={formState.ZoomMax}
                onChange={(e) => handleInputChange('ZoomMax', e.target.value)}
            />
        </form>
    );
};

const FormTabLayers = ({ formData, initialID, existingGroups, sources, onChange }) => {
    const [formState, setFormState] = useState({
        ID: initialID || formData?.id || '',
        Nome: formData?.name || '',
        Origem: formData?.source || '',
        Grupo: formData?.group || '',
        Subgrupo: formData?.subgroup || '',
        WMS: formData?.wmsurl || '',
        WFS: formData?.wfsurl || '',
        WMSLayer: formData?.wmslayer || '',
        MetadataURL: formData?.metadataurl || '',
        SymbolList: formData?.symbollist || [],
        fields: formData?.fields ? formData.fields : [],
        downloads: formData?.downloads ? formData.downloads : []
});

    const [wmsData, setWmsData] = useState({ layers: [] });
    const [error, setError] = useState(null);

    const groups = [];
    for (let grpname in existingGroups) {
        const newgrp = {name: grpname, subgroups: []};
        groups.push(newgrp)
        for (let subgrp of existingGroups[grpname]) {
            const levels = splitSubgroups(subgrp);
            let parent = newgrp;
            for (let subgrpname of levels) {
                subgrpname = subgrpname.trim();
                let subgrp = parent.subgroups.find((s) => s.name == subgrpname);
                if (!subgrp) {
                    subgrp = {name: subgrpname, subgroups: []};
                    parent.subgroups.push(subgrp)
                }
                parent = subgrp;
            }
        }
    }
    const groupoptions = groups.map((g) => g.name).sort();

    useEffect(() => {
        if (formData?.wmslayer && formData?.wmsurl) {
            handleRefreshWMS();
        }
    }, [formData]);

    useEffect(() => {
        if (formState.WMSLayer && wmsData.layers.length > 0) {
            const selectedLayer = wmsData.layers.find((layer) => layer.name === formState.WMSLayer);
            if (selectedLayer) {
                const preSelectedSymbols = formData?.symbollist?.length
                    ? formData.symbollist
                    : [selectedLayer.styles[0]?.name];
                handleInputChange('SymbolList', preSelectedSymbols);
                getWFSURL(formState.WMS,formState.WMSLayer).then((url) => {
                    handleInputChange('WFS', url);
                });
            }
        }
    }, [formState.WMSLayer, wmsData]);

    const handleRefreshWMS = async () => {
        try {
            const capabilities = await wmsCapabilities(formState.WMS);
            setWmsData(capabilities);

            if (formData?.wmslayer) {
                handleInputChange('WMSLayer', formData.wmslayer);
            } else if (capabilities.layers.length > 0) {
                handleInputChange('WMSLayer', capabilities.layers[0]?.name);
            }
        } catch (err) {
            setError(err.message);
            setWmsData({ layers: [] });
        }
    };

    const handleRefreshWFS = async () => {
        const fields = await wfsGetFields(formState.WFS);
        let downloadURLs = await wfsGetDownloadURLs(formState.WFS);
        let id = 0;
        if (formState.downloads.length > 0)
            id = Math.max(...formState.downloads.map((d) => d.id)) + 1;
        downloadURLs = downloadURLs.map((d,i) => {return {type: d.type, url: d.url, id: i+id}});
        const updatedFormState = { ...formState, fields: fields, downloads: downloadURLs };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    }

    const handleInputChange = (field, value) => {
        const updatedFormState = { ...formState, [field]: value };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const handleSymbolChange = (symbolName, isSelected) => {
        const updatedSymbols = isSelected
            ? [...formState.SymbolList, symbolName]
            : formState.SymbolList.filter((name) => name !== symbolName);
        handleInputChange('SymbolList', updatedSymbols);
    };

    const selectedLayer = wmsData.layers.find((layer) => layer.name === formState.WMSLayer);

    const addAtributo = (name = "", type = "string") => {
        const updatedFields = [...formState.fields, { name: name, type: type }];
        const updatedFormState = { ...formState, fields: updatedFields };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const updateAtributo = (index, key, value) => {
        const updatedFields = [...formState.fields];
        updatedFields[index][key] = value;
        const updatedFormState = { ...formState, fields: updatedFields };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const removeAtributo = (index) => {
        const updatedFields = formState.fields.filter((_, i) => i !== index);
        const updatedFormState = { ...formState, fields: updatedFields };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const addDownload = (url = "", type = "SHAPE-ZIP") => {
        let id = 0;
        if(formState.downloads.length > 0)
            id = Math.max(...formState.downloads.map((d) => d.id)) + 1;
        const updatedDownloads = [...formState.downloads, { id: id, url: url, type: type }];
        const updatedFormState = { ...formState, downloads: updatedDownloads };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const updateDownload = (index, key, value) => {
        const updatedDownloads = [...formState.downloads];
        const find = updatedDownloads.find((d) => d.id == index);
        if (!find)
            return;
        find[key] = value;
        const updatedFormState = { ...formState, downloads: updatedDownloads };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const removeDownload = (index) => {
        const updatedDownloads = formState.downloads.filter((d) => d.id !== index);
        const updatedFormState = { ...formState, downloads: updatedDownloads };
        setFormState(updatedFormState);
        onChange(updatedFormState);
    };

    const subgroupitems = [];
    let i = 0;
    if (formData?.Subgrupo) {
        for (let subgrp of splitSubgroups(formData?.Subgrupo)) {
            subgroupitems.push(
                <AutocompleteInput
                    key={"subgroupitems-"+i}
                    value={subgrp}
                    onChange={(value) => handleInputChange('Subgrupo', value)}
                    options={existingGroups[formState.Grupo] ? existingGroups[formState.Grupo] : []}
                />);
            i = i+1;
        }
    }
    subgroupitems.push(<AutocompleteInput
        key={"subgroupitems-"+i}
        value={""}
        onChange={(value) => handleInputChange('Subgrupo', value)}
        options={[]}
    />)

    return (
        <form className="edit-form">
            <label>ID</label>
            <input
                type="text"
                value={formState.ID}
                readOnly
                className="inactive-input"
            />

            <label>Nome</label>
            <input
                type="text"
                value={formState.Nome}
                onChange={(e) => handleInputChange('Nome', e.target.value)}
            />

            <label>Origem</label>
            <AutocompleteInput
                type="text"
                value={formState.Origem}
                onChange={(value) => handleInputChange('Origem', value)}
                options={sources}
            />

            <label>Grupo</label>
            <AutocompleteInput
                value={formState.Grupo}
                onChange={(value) => handleInputChange('Grupo', value)}
                options={groupoptions}
            />

            <label>Subgrupo</label>
            <div className="checkbox-group">{subgroupitems}</div>
            <label>WMS</label>
            <div className="input-with-refresh">
                <input
                    type="text"
                    value={formState.WMS}
                    onChange={(e) => handleInputChange('WMS', e.target.value)}
                />
                <button type="button" onClick={handleRefreshWMS}><IcoRefreshSmall /></button>
            </div>

            <label>Camada</label>
            <select
                value={formState.WMSLayer}
                onChange={(e) => handleInputChange('WMSLayer', e.target.value)}
            >
                <option value="" disabled>
                    Selecione uma camada
                </option>
                {wmsData.layers.map((layer, index) => (
                    <option key={index} value={layer.name}>
                        {layer.name} - {layer.title}
                    </option>
                ))}
            </select>

            <label>Símbolos</label>
            <div className="checkbox-group">
                {selectedLayer && 
                    <label className="checkbox-item"> 
                        <input
                            type="checkbox"
                            checked={true}
                            disabled={true}
                        />
                        Padrão
                    </label>
                }
                {selectedLayer && selectedLayer.styles.length > 0 ? (
                    selectedLayer.styles.map((style, index) => (
                        <label key={index} className="checkbox-item">
                            <input
                                type="checkbox"
                                checked={formState.SymbolList.includes(style.name)}
                                onChange={(e) => handleSymbolChange(style.name, e.target.checked)}
                            />
                            {style.title || style.name}
                        </label>
                    ))
                ) : (
                    <div></div>
                )}
            </div>

            <label>Metadados</label>
            <input
                type="text"
                value={formState.MetadataURL}
                onChange={(e) => handleInputChange('MetadataURL', e.target.value)}
            />

            <label>WFS</label>
            <div className="input-with-refresh">
                <input
                    type="text"
                    value={formState.WFS}
                    onChange={(e) => handleInputChange('WFS', e.target.value)}
                />
                <button type="button" onClick={handleRefreshWFS}><IcoRefreshSmall /></button>
            </div>

            <label className='table-group'>Atributos</label>
            <div className="table-container">
                <div className="table-row">
                    <div className="table-cell table-header">Nome</div>
                    <div className="table-cell table-cell-sm table-header">Tipo</div>
                    <div className="table-cell actions">
                        <button type="button" onClick={() => addAtributo()} className="add-attribute">+</button>
                    </div>
                </div>
                {formState.fields.length === 0 ? (
                    <div className="table-row">
                        <div className="table-cell noData" colSpan="3">Nenhum atributo adicionado.</div>
                    </div>
                ) : (
                    formState.fields.map((attr, index) => (
                        <div key={index} className="table-row">
                            <div className="table-cell alt-border">
                                <input
                                    type="text"
                                    value={attr.name}
                                    onChange={(e) => updateAtributo(index, 'name', e.target.value)}
                                />
                            </div>
                            <div className="table-cell table-cell-sm alt-border">
                                <select
                                    value={attr.type}
                                    onChange={(e) => updateAtributo(index, 'type', e.target.value)}
                                >
                                    <option value="string">Texto</option>
                                    <option value="int">Inteiro</option>
                                    <option value="number">Número</option>
                                    <option value="boolean">Booleano</option>
                                </select>
                            </div>
                            <div className="table-cell actions">
                                <button type="button" onClick={() => removeAtributo(index)}>
                                    <IcoTrashOrange />
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            <label className='table-group'>Downloads</label>
            <div className="table-container">
                <div className="table-row">
                    <div className="table-cell table-header">URL</div>
                    <div className="table-cell table-cell-sm table-header">Tipo</div>
                    <div className="table-cell actions">
                        <button type="button" onClick={()=>addDownload()} className="add-attribute">+</button>
                    </div>
                </div>
                {formState.downloads.length === 0 ? (
                    <div className="table-row">
                        <div className="table-cell noData" colSpan="3">Nenhum download adicionado.</div>
                    </div>
                ) : (
                    formState.downloads.map((download) => (
                        <div key={"download-url-"+download.id} className="table-row">
                            <div className="table-cell alt-border">
                                <input
                                    type="text"
                                    value={download.url}
                                    onChange={(e) => updateDownload(download.id, 'url', e.target.value)}
                                />
                            </div>
                            <div className="table-cell table-cell-sm alt-border">
                                <AutocompleteInput 
                                    value={download.type}
                                    onChange={(value) => updateDownload(download.id, 'type', value)}
                                    options={["KML","KML/WMS","SHAPE-ZIP","XLSX","GeoJSON"]}
                                />
                                
                            </div>
                            <div className="table-cell actions">
                                <button type="button" onClick={() => removeDownload(download.id)}>
                                    <IcoTrashOrange />
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </form>
    );
};

const FormTabUser = ({ formData, initialID }) => {
    const [selectedGroups, setSelectedGroups] = useState(Array.isArray(formData?.Grupos) ? formData.Grupos : []);
    const [groupInput, setGroupInput] = useState('');

    const availableGroups = ['Editores', 'Revisores', 'Administradores', 'Publicadores'];

    const addGroup = () => {
        if (groupInput && !selectedGroups.includes(groupInput)) {
            setSelectedGroups([...selectedGroups, groupInput]);
            setGroupInput('');
        }
    };

    const removeGroup = (group) => {
        setSelectedGroups(selectedGroups.filter(g => g !== group));
    };

    return (
        <form className="edit-form">
            <label>ID</label>
            <input type="text" value={initialID || (formData ? formData.ID : '')} readOnly className="inactive-input" />

            <label>Nome</label>
            <input type="text" defaultValue={formData ? formData.Nome : ''} />

            <label>Sobrenome</label>
            <input type="text" defaultValue={formData ? formData.Sobrenome : ''} />

            <label>Email</label>
            <input type="email" defaultValue={formData ? formData.Email : ''} />

            <label>Instituição</label>
            <input type="text" defaultValue={formData ? formData.Instituição : ''} />

            <label>Grupos</label>
            <div className="group-selection">
                <AutocompleteInput
                    value={groupInput}
                    onChange={setGroupInput}
                    options={availableGroups.filter(group => !selectedGroups.includes(group))}
                />
                <button type="button" className="add-group-btn" onClick={addGroup}>
                    <span>+</span>
                </button>
            </div>
            <div className="selected-groups">
                {selectedGroups.map(group => (
                    <span key={group} className="group-tag">
                        {group}
                        <button type="button" onClick={() => removeGroup(group)}>&times;</button>
                    </span>
                ))}
            </div>
        </form>
    );
};


const FormTabGroup = ({ formData, initialID }) => {
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        const initialPermissions = formData?.Permissoes?.reduce((acc, { model_name, action }) => {
            if (!acc[model_name]) acc[model_name] = [];
            acc[model_name].push(action);
            return acc;
        }, {});
        setPermissions(initialPermissions || {});
    }, [formData]);

    const handlePermissionChange = (area, permission, checked) => {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [area]: checked
                ? [...(prevPermissions[area] || []), permission]
                : (prevPermissions[area] || []).filter(p => p !== permission)
        }));
    };

    const renderPermissionRow = (area, label) => {
        const currentPermissions = permissions[area] || [];
        return (
            <tr>
                <td className="permission-label">{label}</td>
                {['Ler', 'Inserir', 'Editar', 'Excluir'].map(permission => (
                    <td key={permission} className="permission-checkbox">
                        <input
                            type="checkbox"
                            checked={currentPermissions.includes(permission)}
                            onChange={(e) => handlePermissionChange(area, permission, e.target.checked)}
                        />
                    </td>
                ))}
            </tr>
        );
    };

    return (
        <form className="edit-form">
            <label>ID</label>
            <input type="text" value={initialID || (formData ? formData.ID : '')} readOnly className="inactive-input" />

            <label>Nome</label>
            <input type="text" defaultValue={formData ? formData.Nome : ''} />

            <label>Descrição</label>
            <textarea defaultValue={formData ? formData.Descricao : ''} />

            <label className='up-label'>Permissões</label>
            <div className="permissions-section">
                <table className="permissions-table">
                    <thead>
                        <tr>
                            <th>Áreas do Módulo Admin</th>
                            <th>Ler</th>
                            <th>Inserir</th>
                            <th>Editar</th>
                            <th>Excluir</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderPermissionRow('Basemap', 'Cadastro de Mapas Base')}
                        {renderPermissionRow('Layer', 'Configurar Camada')}
                        {renderPermissionRow('User', 'Gerenciar Usuários')}
                        {renderPermissionRow('Services', 'Configurar Serviços')}
                        {renderPermissionRow('Log', 'Logs e Acessos')}
                    </tbody>
                </table>
            </div>
        </form>
    );
};

const FormTabConfigService = ({ formData, initialID }) => (
    <form className="edit-form">
        {/* Form ConfigService */}
    </form>
);

export { FormTabBaseMaps, FormTabLayers, FormTabUser, FormTabGroup, FormTabConfigService };