import React, { useState, useEffect } from 'react';
import './topbar.css';
import {
    IcoAddWorkorder, IcoRefresh,
    IcoFilter, IcoOkCircleFilled,
    IcoXAdd, IcoTrashRed,
    IcoFilterWhite
} from '../../img/ico/icoBtn';
import {
    fetchBaseMaps,
    fetchLayers,
    fetchUsers,
    fetchGroups,
    deleteItem,
    updateItem
} from '../Connector';
import { applyFilter } from '../FilterUtils';

const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, delay);
    };
};

const FilterComponent = ({ isFilterVisible, setFilterVisible, filter, setFilter }) => {
    const [inputBorder, setInputBorder] = useState('1px solid #ccc');

    const handleFilterInput = (e) => {
        const text = e.target.value;
        console.log("---",text)
        setFilter(text);
    };

    const toggleFilterVisibility = () => {
        setFilterVisible(!isFilterVisible);
        if (isFilterVisible)
            setFilter('');
        setInputBorder('1px solid #ccc');
    };

    return (
        <div className="filter-container">
            <button onClick={toggleFilterVisibility}><IcoFilter /></button>
            {isFilterVisible && (
                <div className='filter-modal'>
                    <button onClick={toggleFilterVisibility}><IcoFilterWhite /></button>
                    <input
                        type="text"
                        value={filter}
                        onChange={handleFilterInput}
                        placeholder="Filtrar..."
                        style={{ border: inputBorder }}
                    />
                    <button className='btn-x' onClick={toggleFilterVisibility}>&times;</button>
                </div>
            )}
        </div>
    );
};

const NavBar = ({ currentPage, totalItems, pageSize, onPageChange, onPageSizeChange }) => {
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize + 1;
    const lastItemIndex = lastIndex > totalItems ? totalItems : lastIndex;

    return (
        <div className="nav-bar">
            <div className='result-item'>
                <span>{firstIndex}-{lastItemIndex} de {totalItems}</span>
            </div>
            <div className='nav-btn'>
                <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 1}><span>&#x25c0;</span></button>
                <span className='result-page'>{currentPage}</span>
                <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage * pageSize >= totalItems}><span>&#x25b6;</span></button>
            </div>
            <div className='page-size'>
                <label htmlFor="page-size-input">Itens por página: </label>
                <input
                    id="page-size-input"
                    type="number"
                    min="1"
                    value={pageSize}
                    onChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
                />
            </div>
        </div>
    );
};

const handleDelete = (apiUrl, itemId, loadData, removeTab, selectedTabIndex) => {
    deleteItem(apiUrl, itemId, loadData);
    removeTab(selectedTabIndex);
};

const handleUpdate = (apiUrl, itemId, loadData, removeTab, selectedTabIndex, formData, operation) => {
    console.log("&&",formData)
    updateItem(apiUrl, itemId, loadData, removeTab, selectedTabIndex, formData, operation);
    removeTab(selectedTabIndex);
};

const handleCancel = (removeTab, selectedTabIndex) => {
    const isConfirmed = window.confirm('Fechar esta aba e cancelar a edição?');
    if (isConfirmed && removeTab) {
            removeTab(selectedTabIndex);
        }
    removeTab(selectedTabIndex);
};

const TBCarCamada = () => (
    <div className="top-bar">
        <div className="btn-container">
            <button><IcoOkCircleFilled /></button>
            <button><IcoXAdd /></button>
        </div>
    </div>
);

const TBBaseMaps = ({ addTab, showSecondaryButtons, currentPage, totalItems, pageSize, onPageChange, onPageSizeChange, onRefresh, filter, setFilter, initialID, removeTab, selectedTabIndex, formData }) => {
    const [isFilterVisible, setFilterVisible] = useState(false);

    const formDataMap = {
        name: formData?.Nome || '',
        url: formData?.URL || '',
        type: formData?.Tipo || '',
        attribution: formData?.Atribuições || '',
        priority: formData?.Prioridade || '',
        default: formData?.Padrão,
        min_zoom: parseInt(formData?.ZoomMin, 10) || 0,
        max_zoom: parseInt(formData?.ZoomMax, 10) || 0
    };

    return (
        <div className="top-bar">
            {showSecondaryButtons ? (
                initialID ? (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('basemaps', initialID, onRefresh, removeTab, selectedTabIndex, formDataMap, 'update')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                        <button onClick={() => handleDelete('basemaps', initialID, onRefresh, removeTab, selectedTabIndex)}><IcoTrashRed /></button>
                    </div>
                ) : (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('basemaps', initialID, onRefresh, removeTab, selectedTabIndex, formDataMap, 'create')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                    </div>
                )
            ) : (
                <>
                    <div className="btn-container">
                        <button onClick={addTab}><IcoAddWorkorder /></button>
                        <button onClick={onRefresh}><IcoRefresh /></button>
                        <FilterComponent
                            isFilterVisible={isFilterVisible}
                            setFilterVisible={setFilterVisible}
                            activePage="CadMapa"
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                    <NavBar
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                </>
            )}
        </div>
    );
};

const TBLayers = ({ addTab, showSecondaryButtons, currentPage, totalItems, pageSize, onPageChange, onPageSizeChange, onRefresh, filter, setFilter, initialID, removeTab, selectedTabIndex, formData }) => {
    const [isFilterVisible, setFilterVisible] = useState(false);

    const formDataMap = {
        id: formData?.id || '',
        name: formData?.Nome || '',
        source: formData?.Origem || '',
        group: formData?.Grupo || '',
        subgroup: formData?.Subgrupo || '',
        wmsurl: formData?.WMS || '',
        wfsurl: formData?.WFS || '',
        wmslayer: formData?.WMSLayer || '',
        metadataurl: formData?.MetadataURL || '',
        symbollist: formData?.SymbolList?.map((s,i) => {return {name:s, sldname: s, default: i==0}}) || [],
        fields: formData?.fields || [],
        downloads: formData?.downloads,
        metadataurl: formData?.MetadataURL || ''
    };

    return (
        <div className="top-bar">
            {showSecondaryButtons ? (
                initialID ? (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('layers', initialID, onRefresh, removeTab, selectedTabIndex, formDataMap, 'update')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                        <button onClick={() => handleDelete('layers', initialID, onRefresh, removeTab, selectedTabIndex)}><IcoTrashRed /></button>
                    </div>
                ) : (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('layers', initialID, onRefresh, removeTab, selectedTabIndex, formDataMap, 'create')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                    </div>
                )
            ) : (
                <>
                    <div className="btn-container">
                        <button onClick={addTab}><IcoAddWorkorder /></button>
                        <button onClick={onRefresh}><IcoRefresh /></button>
                        <FilterComponent
                            isFilterVisible={isFilterVisible}
                            setFilterVisible={setFilterVisible}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                    <NavBar
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                </>
            )}
        </div>
    );
};

const TBUser = ({ addTab, showSecondaryButtons, currentPage, totalItems, pageSize, onPageChange, onPageSizeChange, onRefresh, onFilter, initialID, removeTab, selectedTabIndex, formData }) => {
    const [isFilterVisible, setFilterVisible] = useState(false);

    return (
        <div className="top-bar">
            {showSecondaryButtons ? (
                initialID ? (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('users', initialID, onRefresh, removeTab, selectedTabIndex, formData, 'update')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                        <button onClick={() => handleDelete('users', initialID, onRefresh, removeTab, selectedTabIndex)}><IcoTrashRed /></button>
                    </div>
                ) : (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('users', initialID, onRefresh, removeTab, selectedTabIndex, formData, 'create')}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                    </div>
                )
            ) : (
                <>
                    <div className="btn-container">
                        <button onClick={addTab}><IcoAddWorkorder /></button>
                        <button onClick={onRefresh}><IcoRefresh /></button>
                        <FilterComponent
                            isFilterVisible={isFilterVisible}
                            setFilterVisible={setFilterVisible}
                            activePage="GerUsuario"
                            setData={onFilter}
                        />
                    </div>
                    <NavBar
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                </>
            )}
        </div>
    );
};

const TBGroup = ({ addTab, showSecondaryButtons, currentPage, totalItems, pageSize, onPageChange, onPageSizeChange, onRefresh, onFilter, initialID, removeTab, selectedTabIndex, formData }) => {
    const [isFilterVisible, setFilterVisible] = useState(false);

    return (
        <div className="top-bar">
            {showSecondaryButtons ? (
                initialID ? (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('groups', 'update', initialID, onRefresh, removeTab, selectedTabIndex, formData)}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                        <button onClick={() => handleDelete('groups', initialID, onRefresh, removeTab, selectedTabIndex)}><IcoTrashRed /></button>
                    </div>
                ) : (
                    <div className="btn-container">
                        <button onClick={() => handleUpdate('groups', 'create', initialID, onRefresh, removeTab, selectedTabIndex, formData)}><IcoOkCircleFilled /></button>
                        <button onClick={() => handleCancel(removeTab, selectedTabIndex)}><IcoXAdd /></button>
                    </div>
                )
            ) : (
                <>
                    <div className="btn-container">
                        <button onClick={addTab}><IcoAddWorkorder /></button>
                        <button onClick={onRefresh}><IcoRefresh /></button>
                        <FilterComponent
                            isFilterVisible={isFilterVisible}
                            setFilterVisible={setFilterVisible}
                            activePage="GerGrupos"
                            setData={onFilter}
                        />
                    </div>
                    <NavBar
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                </>
            )}
        </div>
    );
};

const TBConfigService = () => (
    <div className="top-bar">
        <div className="btn-container">
            <button><IcoOkCircleFilled /></button>
            <button><IcoRefresh /></button>
            <button><IcoFilter /></button>
        </div>
    </div>
);

const TBLogAcesso = () => (
    <div className="top-bar">
        <div className="btn-container">
            <button><IcoOkCircleFilled /></button>
            <button><IcoRefresh /></button>
            <button><IcoFilter /></button>
        </div>
    </div>
);

export {
    FilterComponent,
    TBCarCamada,
    TBBaseMaps,
    TBLayers,
    TBConfigService,
    TBUser,
    TBGroup,
    TBLogAcesso
};