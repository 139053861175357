import '../style.css';
import React from 'react';
import { decode, encode } from 'js-base64'
import {
    IcoPencil, IcoTrashOrange,
    IcoEye, IcoGlobe,
} from '../../img/ico/icoBtn';
import { deleteItem, GEOVI_URL } from '../Connector';

const ActionBaseMaps = ({ row, editRow, loadData }) => {
    const openMap = () => {
        const config = {
            "showMyPosition": false,
            "basemap": row.ID,
            "layers": [],
            "mapBounds": []
        };
        const url = GEOVI_URL + "?" +encode(JSON.stringify(config));
        window.open(url, '_blank');
    };
    return (
        <div className="btn-list">
            <div className="btn-items">
                <button onClick={() => editRow(row)}><IcoPencil /></button>
                <button onClick={() => deleteItem('basemaps', row.ID, loadData)}><IcoTrashOrange /></button>
                <button><IcoEye /></button>
                <button onClick={openMap}><IcoGlobe /></button>
            </div>
        </div>
    );
};

const ActionLayers = ({ row, editRow, loadData }) => {
    const openMap = () => {
        const config = {
            basemap: undefined,
            layers: [row.ID],
            showMyPosition: false,
            mapBounds: []
        };
        const url = GEOVI_URL + "/?" + encode(JSON.stringify(config));
        window.open(url, '_blank');
    };
    return (
        <div className="btn-list">
            <div className="btn-items">
                <button onClick={() => editRow(row)}><IcoPencil /></button>
                <button onClick={() => deleteItem('layers', row.ID, loadData)}><IcoTrashOrange /></button>
                <button><IcoEye /></button>
                <button onClick={openMap}><IcoGlobe /></button>
            </div>
        </div>
    );
};

const ActionUsers = ({ row, editRow, loadData }) => (
    <div className="btn-list">
        <div className="btn-items">
            <button onClick={() => editRow(row)}><IcoPencil /></button>
            <button onClick={() => deleteItem('users', row.ID, loadData)}><IcoTrashOrange /></button>
            <button><IcoEye /></button>
        </div>
    </div>
);

const ActionGroups = ({ row, editRow, loadData }) => (
    <div className="btn-list">
        <div className="btn-items">
            <button onClick={() => editRow(row)}><IcoPencil /></button>
            <button onClick={() => deleteItem('groups', row.ID, loadData)}><IcoTrashOrange /></button>
            <button><IcoEye /></button>
        </div>
    </div>
);

const ActionConfigService = ({ loadData }) => (
    <div className="btn-list">
        <div className="btn-items">
            <button><IcoPencil /></button>
            <button onClick={() => deleteItem('services', 'serviceId', loadData)}><IcoTrashOrange /></button>
            <button><IcoEye /></button>
            <button><IcoGlobe /></button>
        </div>
    </div>
);

const ActionLogAcesso = ({ loadData }) => (
    <div className="btn-list">
        <div className="btn-items">
            <button><IcoPencil /></button>
            <button onClick={() => deleteItem('logs', 'logId', loadData)}><IcoTrashOrange /></button>
            <button><IcoEye /></button>
            <button><IcoGlobe /></button>
        </div>
    </div>
);

export { ActionBaseMaps, ActionLayers, ActionConfigService, ActionUsers, ActionGroups, ActionLogAcesso };