export const API_URL = process.env.REACT_APP_API_URL.endsWith("/") ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL + "/";
export const GEOVI_URL = process.env.REACT_APP_GEOVI_URL.endsWith("/") ? process.env.REACT_APP_GEOVI_URL : process.env.REACT_APP_GEOVI_URL + "/";

const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiry');
};

let loggedUserInfo = null;

const validateAuth = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    try {
        const response = await fetch(`${API_URL}users/me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 401) {
            return false;
        }

        if (response.ok) {
            const userInfo = await response.json();
            storeUserInfo(userInfo);
            return true;
        }
    } catch (error) {
        console.error('Erro ao validar autenticação:', error);
        return false;
    }
};

const storeUserInfo = (userInfo) => {
    const groups = userInfo.data.groups || [];
    const permissions = groups.flatMap(group =>
        (group.permissions || []).map(permission => `${permission.model_name}:${permission.action}`)
    );

    loggedUserInfo = {
        ID: userInfo.data.id,
        Nome: userInfo.data.first_name,
        Sobrenome: userInfo.data.last_name,
        Email: userInfo.data.email,
        Admin: userInfo.data.admin,
        Grupos: groups.map(group => group.name).join(' / '),
        Permissoes: permissions
    };
};

const checkUserAuthentication = async () => {
    const isLogged = await validateAuth();
    const isAdmin = isLogged && loggedUserInfo?.Admin === true;
    return {
        isLogged,
        isAdmin
    };
};

const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem('authToken');
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.json();
};

const performCRUDOperation = async (method, endpoint, data = null) => {
    try {
        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...(localStorage.getItem('authToken') ? { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } : {})
            },
            body: data ? JSON.stringify(data) : null,
        };

        const response = await fetch(`${API_URL}${endpoint}`, options);
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Resposta ao erro:', errorText);
            throw new Error(`Falha ao ${method} dado: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`Erro em ${method} solicitação para ${endpoint}:`, error);
        throw error;
    }
};

const createItem = async (endpoint, data) => {
    return performCRUDOperation('POST', endpoint, data);
};

const updateItem = async (endpoint, itemId, loadDataFunc, removeTab, selectedTabIndex, formData, operation) => {
    const { ID, ...formWithoutID } = formData;
    const confirmMessage = operation === 'create' ?
        'Deseja criar o novo item?' :
        `Deseja finalizar edição e atualizar o item: ${itemId}?`;

    //const isConfirmed = window.confirm(confirmMessage);

    //if (!isConfirmed) {
    //    return;
    //}

    try {
        const url = operation === 'create' ? `${API_URL}${endpoint}` : `${API_URL}${endpoint}/${itemId}`;
        const method = operation === 'create' ? 'POST' : 'PUT';

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...(localStorage.getItem('authToken') ? { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } : {})
            },
            body: JSON.stringify(formWithoutID)
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Resposta ao erro:', errorText);
            throw new Error(`Falha ao ${method === 'POST' ? 'criar' : 'atualizar'} dado: ${response.status}`);
        }

        alert(`Item ${operation === 'create' ? 'criado' : `(${itemId}) atualizado`} com sucesso.`);

        if (loadDataFunc) loadDataFunc();
        if (removeTab) removeTab(selectedTabIndex);

    } catch (error) {
        console.error(`Falha ao ${operation === 'create' ? 'criar' : 'atualizar'} dados`, error);
    }
};

const deleteItem = async (endpoint, itemId, loadData) => {
    const isConfirmed = window.confirm(`Tem certeza de que deseja excluir o item: ${itemId}?`);

    if (!isConfirmed) {
        return;
    }

    try {
        const response = await fetch(`${API_URL}${endpoint}/${itemId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...(localStorage.getItem('authToken') ? { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } : {})
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Resposta ao erro:', errorText);
            throw new Error(`Falha ao excluir dado: ${response.status}`);
        }

        if (loadData) loadData();

        alert(`Item ${itemId} apagado com sucesso.`);
    } catch (error) {
        console.error('Falha ao excluir dados', error);
    }
};

const fetchBaseMaps = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}basemaps`);
        const data = Array.isArray(response) ? response : response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return [];
        }
        return data.map(item => ({
            ID: item.id,
            Nome: item.name,
            URL: item.url,
            Atribuições: item.attribution,
            Tipo: item.type,
            Padrão: item.default,
            Prioridade: item.priority,
            ZoomMin: item.min_zoom,
            ZoomMax: item.max_zoom
        }));
    } catch (error) {
        console.error('Falha ao buscar Mapas Base', error);
        return [];
    }
};

const fetchBaseMapsPaginated = async (page = 1, size, filter = "") => {
    try {
        let url = `${API_URL}basemaps/page/${size}?page=${page}`;
        console.log("**",filter)
        if (filter) {
            const fields = ["name","url","type"];
            let filters = "";
            for (let i=0; i<fields.length;++i)
                filters += `&filters[$or][${i}][${fields[i]}][$contains]=${filter}`;
            url += filters;
        }
        const response = await fetchWithAuth(url);
        const data = response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return { items: [], pagination: {} };
        }
        return {
            items: data.map(item => ({
                ID: item.id,
                Nome: item.name,
                URL: item.url,
                Atribuições: item.attribution,
                Padrão: item.default,
                Tipo: item.type,
                Prioridade: item.priority,
                ZoomMin: item.min_zoom,
                ZoomMax: item.max_zoom
            })),
            pagination: response.meta
        };
    } catch (error) {
        console.error('Falha ao buscar Mapas Base paginados', error);
        return { items: [], pagination: {} };
    }
};

const fetchLayers = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}layers`);
        const data = Array.isArray(response) ? response : response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return { gridData: [], fullData: [] };
        }
        data.sort((a,b) => a.id - b.id);
        const gridData = data.map(item => ({
            ID: item.id,
            Nome: item.name,
            Origem: item.source,
            'Grupos/Subgrupos': `${item.group} / ${item.subgroup}`,
            'WFS?': item.wfsurl ? 'Sim' : 'Não',
            'Meta?': item.metadataurl ? 'Sim' : 'Não',
            'Download?': item.downloads && item.downloads.length > 0 ? 'Sim' : 'Não'
        }));
        return {
            gridData,
            fullData: data,
        };
    } catch (error) {
        console.error('Falha ao buscar Camadas', error);
        return { gridData: [], fullData: [] };
    }
};

const fetchLayersPaginated = async (page = 1, size, filter = "") => {
    try {
        let url = `${API_URL}layers/page/${size}?page=${page}`;
        if (filter) {
            const fields = ["name","source","group","subgroup"];
            let filters = "";
            for (let i=0; i<fields.length;++i)
                filters += `&filters[$or][${i}][${fields[i]}][$contains]=${filter}`;
            url += filters;
        }
        const response = await fetchWithAuth(url);
        const data = response.data;
        data.sort((a,b) => a.id - b.id);
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return { gridData: [], fullData: [], pagination: {} };
        }
        const gridData = data.map(item => ({
            ID: item.id,
            Nome: item.name,
            Origem: item.source,
            'Grupos/Subgrupos': `${item.group} / ${item.subgroup}`,
            'WFS?': item.wfsurl ? 'Sim' : 'Não',
            'Meta?': item.metadataurl ? 'Sim' : 'Não',
            'Download?': item.downloads && item.downloads.length > 0 ? 'Sim' : 'Não'
        }));
        return {
            gridData,
            fullData: data,
            pagination: response.meta
        };
    } catch (error) {
        console.error('Falha ao buscar Camadas paginadas', error);
        return { gridData: [], fullData: [], pagination: {} };
    }
};

const fetchLayerSources = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}layers/sources`);
        const data = Array.isArray(response) ? response : response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return [];
        }
        return data;
    } catch (error) {
        console.error('Falha ao buscar Mapas Base', error);
        return [];
    }
};

const fetchLayerGroups = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}layers/groups`);
        return response;
    } catch (error) {
        console.error('Falha ao buscar Mapas Base', error);
        return [];
    }
};

const fetchUsers = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}users`);
        const data = Array.isArray(response) ? response : response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return [];
        }
        return data.map(item => ({
            ID: item.id,
            Nome: item.first_name,
            Sobrenome: item.last_name,
            Email: item.email,
            Admin: item.admin,
            Grupos: item.groups.join(' / '),
        }));
    } catch (error) {
        console.error('Falha ao buscar Usuários', error);
        return [];
    }
};

const fetchUsersPaginated = async (page = 1, size) => {
    try {
        const response = await fetchWithAuth(`${API_URL}users/page/${size}?page=${page}`);
        const data = response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return { items: [], pagination: {} };
        }
        return {
            items: data.map(item => ({
                ID: item.id,
                Nome: item.first_name,
                Sobrenome: item.last_name,
                Email: item.email,
                Admin: item.admin,
                Grupos: item.groups.length ? item.groups.map(group => group.name).join(' / ') : ''
            })),
            pagination: response.meta
        };
    } catch (error) {
        console.error('Falha ao buscar Usuários paginados', error);
        return { items: [], pagination: {} };
    }
};

const fetchGroups = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}groups`);
        const data = Array.isArray(response) ? response : response.data;
        if (!Array.isArray(data)) {
            console.error('Formato de dados inesperado:', data);
            return [];
        }
        return data.map(item => ({
            ID: item.id,
            Nome: item.name,
            Descrição: item.description,
            Permissões: item.permissions.join(' / '),
        }));
    } catch (error) {
        console.error('Falha ao buscar Grupos', error);
        return [];
    }
};

const fetchGroupsPaginated = async (page = 1, size) => {
    try {
        const data = await fetchWithAuth(`${API_URL}groups/page/${size}?page=${page}`);
        const modelNameMapping = {
            'Basemap': 'Carregar',
            'Layer': 'Camadas',
            'User': 'Usuários',
            'Services': 'Serviços',
            'Log': 'Logs e Acessos'
        };

        const gridData = data.data.map(item => {
            const permissionsColumns = {
                Carregar: '',
                Camadas: '',
                Usuários: '',
                Serviços: '',
                'Logs e Acessos': ''
            };

            item.permissions.forEach(permission => {
                const columnName = modelNameMapping[permission.model_name];
                if (columnName) {
                    permissionsColumns[columnName] = permission.action;
                }
            });

            return {
                ID: item.id,
                Nome: item.name,
                Descricao: item.description,
                ...permissionsColumns
            };
        });

        const formData = data.data.map(item => ({
            ID: item.id,
            Nome: item.name,
            Descricao: item.description,
            Permissoes: item.permissions.map(permission => ({
                model_name: permission.model_name,
                action: permission.action
            }))
        }));

        return {
            gridItems: gridData,
            formItems: formData,
            pagination: data.meta
        };
    } catch (error) {
        console.error('Falha ao buscar Grupos', error);
        return { gridItems: [], formItems: [], pagination: {} };
    }
};

export {
    validateAuth,
    logout,
    fetchBaseMaps,
    fetchBaseMapsPaginated,
    fetchLayers,
    fetchLayersPaginated,
    fetchLayerSources,
    fetchLayerGroups,
    fetchUsers,
    fetchUsersPaginated,
    fetchGroups,
    fetchGroupsPaginated,
    loggedUserInfo,
    createItem,
    updateItem,
    deleteItem,
    checkUserAuthentication
};