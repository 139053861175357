import React from 'react';
import {
    ActionBaseMaps, ActionLayers,
    ActionConfigService, ActionUsers,
    ActionGroups, ActionLogAcesso
} from '../ActionBtn';

const Grid = ({ headers, data, area, editRow, isLoading, loadData }) => {
    const columnCount = headers.length;
    let gridTemplateColumns = `48px repeat(${columnCount - 2}, 1fr) 170px`;

    const getActionComponent = (row) => {
        switch (area) {
            case 'CadMapa':
                return <ActionBaseMaps row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            case 'ConfigCamada':
                return <ActionLayers row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            case 'ConfigService':
                return <ActionConfigService row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            case 'GerUsuario':
                return <ActionUsers row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            case 'GerGrupos':
                return <ActionGroups row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            case 'LogAcesso':
                return <ActionLogAcesso row={row} editRow={editRow} isLoading={isLoading} loadData={loadData} />;
            default:
                return null;
        }
    };

    if (isLoading) {
        return (
            <div className='logo-loading-overlay'>
                <div className="logo-loading"></div>
            </div>
        );
    }

    return (
        <div className="grid-container" style={{ gridTemplateColumns }}>
            {headers.map((header, index) => (
                <div key={index} className="grid-item header-item">{header}</div>
            ))}
            {data.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    {headers.slice(0, -1).map((header, cellIndex) => (
                        <div
                            key={`${rowIndex}-${cellIndex}`}
                            className={`grid-item ${rowIndex % 2 === 0 ? 'row-even' : ''}`}
                        >
                            {row[header] === true ? "Sim" : ( row[header] === false ? "Não" : row[header])}
                        </div>
                    ))}
                    <div className={`grid-item ${rowIndex % 2 === 0 ? 'row-even' : ''}`}>
                        {getActionComponent(row)}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Grid;